import CustomerDisplayItemOrCombo from "../CustomerDisplayItemOrCombo";
import React, {CSSProperties} from "react";
import {habitLogo} from "./HabitLogoSVG";
import {API, Lib} from "habit-core";
import {renderToString} from "react-dom/server";

const strings = {
    subtotal: "Subtotal",
    total: "Order Total",
    tax: "Tax",
    yourOrder: "Your order",
    noItems: "No Items Ordered",
    discount: "Discount",
    charityRoundUp: "Charity Round-Up",
};

export type ItemModifierData = {
    modifierId: string;
    modifierName: string;
    selectionId: string;
    selectionName: string;
    priceCents?: API.models.USDCents;
    quantity: number;
};
export type EntityData = Lib.selectors.CartEntityItemOrCombo & {
    priceCents: API.models.USDCents;
    quantity: number;
    comboItems:
        | {
              id: string;
              name: string;
              modifiers: ItemModifierData[];
          }[]
        | null;
    itemModifiers: ItemModifierData[] | null;
};

export type FullOrderData = {
    subtotalCents: API.models.USDCents;
    salesTaxRate: number;
    salesTaxCents: API.models.USDCents;
    charityRoundUpAmountCents: API.models.USDCents;
    discountAmountCents: API.models.USDCents;
    compCardAppliedCents: API.models.USDCents;
    totalCents: API.models.USDCents;
    entities: EntityData[];
};

type Props = {
    fullOrderData: FullOrderData;
    customerDisplayActiveElement:
        | API.models.CampaignExecutionElement
        | undefined;
};

const CustomerDisplay = ({
    fullOrderData,
    customerDisplayActiveElement,
}: Props) => {
    function renderRight() {
        return (
            <div style={styles.rightContainer}>
                <div style={styles.svgContainer}>{habitLogo}</div>
            </div>
        );
    }

    const orderDetailsAmountStyle =
        fullOrderData.charityRoundUpAmountCents !== 0 &&
        fullOrderData.discountAmountCents !== 0
            ? {...styles.amountText, ...styles.amountTextSmall}
            : styles.amountText;

    if (fullOrderData.entities.length === 0) {
        let element: React.ReactNode = (
            <div style={styles.svgContainer}>{habitLogo}</div>
        );
        if (customerDisplayActiveElement) {
            if (customerDisplayActiveElement.type === "image") {
                element = (
                    <img
                        style={styles.imageElement}
                        src={customerDisplayActiveElement.data.images[0].url}
                        alt=""
                    />
                );
            } else {
                element = (
                    <video
                        style={styles.videoElement}
                        src={customerDisplayActiveElement.data.url}
                        autoPlay
                        muted
                        loop
                        preload="metadata"
                        poster="null"
                    />
                );
            }
        }
        return <div style={styles.emptyOrderContainer}>{element}</div>;
    }

    return (
        <div style={styles.container}>
            <div style={styles.leftContainer}>
                <div style={styles.title}>{strings.yourOrder}</div>
                <div style={styles.orderDetailsContainer}>
                    <div style={styles.subtotal}>
                        <div style={styles.headerText}>{strings.subtotal}</div>
                        <div style={orderDetailsAmountStyle}>
                            {Lib.currency.centsToDollarString(
                                fullOrderData.subtotalCents,
                            )}
                        </div>
                    </div>
                    <div style={styles.saleTax}>
                        <div style={styles.headerText}>
                            {strings.tax} (
                            {fullOrderData.salesTaxRate.toFixed(2)}
                            %)
                        </div>
                        <div style={orderDetailsAmountStyle}>
                            {Lib.currency.centsToDollarString(
                                fullOrderData.salesTaxCents,
                            )}
                        </div>
                    </div>
                    {fullOrderData.discountAmountCents !== 0 ? (
                        <div style={styles.discount}>
                            <div style={styles.headerText}>
                                {strings.discount}
                            </div>
                            <div style={orderDetailsAmountStyle}>
                                {Lib.currency.centsToDollarString(
                                    fullOrderData.discountAmountCents,
                                )}
                            </div>
                        </div>
                    ) : null}
                    {fullOrderData.charityRoundUpAmountCents !== 0 ? (
                        <div style={styles.charityRoundUpAmount}>
                            <div style={styles.headerText}>
                                {strings.charityRoundUp}
                            </div>
                            <div style={orderDetailsAmountStyle}>
                                {Lib.currency.centsToDollarString(
                                    fullOrderData.charityRoundUpAmountCents,
                                )}
                            </div>
                        </div>
                    ) : null}
                    <div style={styles.total}>
                        <div style={styles.headerText}>{strings.total}</div>
                        <div style={styles.amountText}>
                            {Lib.currency.centsToDollarString(
                                fullOrderData.totalCents,
                            )}
                        </div>
                    </div>
                </div>
                <div id="itemList" style={styles.orderContainer}>
                    {fullOrderData.entities.length > 0 ? (
                        fullOrderData.entities.map((x, i) => {
                            if (x.type === "item" || x.type === "combo") {
                                return (
                                    <div key={x.customizationId}>
                                        <CustomerDisplayItemOrCombo
                                            entity={x}
                                            isLatestAdded={i === 0}
                                        />
                                    </div>
                                );
                            }

                            return null;
                        })
                    ) : (
                        <div style={styles.noItems}>{strings.noItems}</div>
                    )}
                </div>
            </div>
            {renderRight()}
        </div>
    );
};

const styles: Record<string, CSSProperties> = {
    container: {
        display: "flex",
        height: "100%",
        padding: "1.48vh 0.83vw",
    },
    emptyOrderContainer: {
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        background:
            "linear-gradient(159.2deg, rgba(235, 160, 45, 0.1) 10.6%, rgba(235, 160, 45, 0.25) 91.1%)",
    },
    imageElement: {
        width: "100vw",
        height: "100vh",
    },
    videoElement: {
        width: "100%",
        height: "100%",
    },
    leftContainer: {
        display: "flex",
        flexDirection: "column",
        width: "58vw",
        height: "100%",
        fontSize: "1.1vw",
        paddingRight: "1.67vw",
    },
    rightContainer: {
        width: "42vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background:
            "linear-gradient(159.2deg, rgba(235, 160, 45, 0.1) 10.6%, rgba(235, 160, 45, 0.25) 91.1%)",
        position: "relative",
    },
    svgContainer: {
        height: "25.74vh",
        width: "20.26vw",
    },
    orderContainer: {
        flex: "1",
        padding: "0vh 2.08vw 1.48vh",
        overflowY: "scroll",
    },
    title: {
        fontSize: "1.1vw",
        fontWeight: "600",
        paddingLeft: "2.08vw",
        paddingTop: "3.7vh",
        marginBottom: "2.2vh",
    },
    orderDetailsContainer: {
        padding: "2.2vh 2.08vw",
        background: "#EEEEEE",
        display: "flex",
    },
    headerText: {
        fontSize: "1.04vw",
        fontWeight: "600",
        lineHeight: 1,
        marginBottom: "0.74vh",
    },
    amountText: {
        fontSize: "2.92vw",
        fontWeight: "300",
        lineHeight: 1,
    },
    amountTextSmall: {
        fontSize: "1.1vw",
        fontWeight: "600",
        marginTop: "2.2vh",
    },
    subtotal: {
        display: "block",
        marginRight: "2.6vw",
    },
    saleTax: {
        display: "block",
        marginRight: "2.6vw",
    },
    discount: {
        display: "block",
        marginRight: "3.75vw",
    },
    charityRoundUpAmount: {
        display: "block",
    },
    total: {
        display: "block",
        marginLeft: "auto",
        textAlign: "right",
    },
    noItems: {
        marginTop: "4.8vh",
        fontSize: "2.92vw",
        lineHeight: "1",
        fontWeight: "300",
    },
};

export default CustomerDisplay;

export const getCustomerDisplayHtml = (
    fullOrderData: FullOrderData,
    activeElement: API.models.CampaignExecutionElement | undefined,
): string => {
    const htmlStr = `<body style="overflow:hidden;">
      ${renderToString(
          <CustomerDisplay
              fullOrderData={fullOrderData}
              customerDisplayActiveElement={activeElement}
          />,
      )}
      </body>
      `;
    return htmlStr;
};
