export const users = {
    validateCardNumber: "/users/validate-manager-by-card",
    validateId: "/users/validate-manager-by-id",
    getInfo: "/users/manager/",
    associateCard: "/users/associate-card",
};

export const orders = {
    get: "/orders",
    place: "/orders",
    reserveOrderId: "/orders/reserve-order-id",
    reserveOrderReferenceNumber: "/orders/reserve-order-reference-number",
    paidOut: "/orders/paid-out",
    cancel: "/orders/cancel",
};

export const paymentMethods = {
    noSale: "/payment-methods/no-sale",
    getGiftCardBalance: "/payment-methods/gift-card/",
    getGiftCardBalanceRegex: /\/payment-methods\/gift-card\/\w+/,
    reduceGiftCardBalance: "/payment-methods/gift-card/",
    reduceGiftCardBalanceRegex: /\/payment-methods\/gift-card\/\w+/,
    cashOutGiftCard: (cardNum: string) =>
        `/payment-methods/gift-card/${cardNum}/cash-out`,
    cashOutGiftCardRegex: /\/payment-methods\/gift-card\/\w+\/cash-out/,
    addFundsGiftCard: "/payment-methods/gift-card/add",
};

export const stores = {
    getSettings: "/stores/store-settings",
    getSettingsLastUpdateDate: "/stores/store-settings/last-update-date",
};

export const printers = {
    get: "/printers",
    status: "/printers/status",
    assign: "/printers",
    config: "/printers/config",
    printReceipt: "/printers/receipt",
};

export const discounts = {
    validateOrRedeem: "/discounts",
    unredeem: "/discounts/unredeem/",
};

export const menus = {
    allPrepsRegex: /\/menus\/store\/[\w-]+\/preps/,
    allPreps: (storeId: string) => `/menus/store/${storeId}/preps`,
    addOns: "/menus/add-ons",
    globalMenuLastUpdateDate: "/menus/last-update-date",
    storeMenuLastUpdateDate: (storeId: string) =>
        `/menus/store/${storeId}/last-update-date`,
    storeMenuLastUpdateDateRegex: /\/menus\/store\/[\w-]+\/last-update-date/,
    getByStoreSimpleRegex: /\/menus\/store\/[\w-]+/,
};

export const reports = {
    timePeriod: "/reports/time-period",
    balance: "/reports/balance",
    sales: "/reports/sales-detail",
};

export const tills = {
    logAssignment: "/till/log-assignment",
    getAssignments: "/till/assignments",
    getCurrentAmount: "/till/current-amount",
    logCashPull: "/till/log-cash-pull",
};

export const creditCardScanner = {
    register: "/api/device/register",
    testConnection: "/api/device/testMac",
    processPayment: "/api/payment/processPayment",
};

export const settings = {
    get: "/settings",
};

export const health = {
    getExhaustive: "/health/exhaustive",
};

export const marketing = {
    getExecutionByName: (executionName: string) =>
        `/marketing/executions/${executionName}`,
    getExecutionByNameRegex: /\/marketing\/executions\/\w+/,
};
