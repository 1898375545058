import instance from "./instance";
import {API} from "habit-core";
import {marketing as urls} from "./urls";

export function getExecutionData(
    executionName: string,
): Promise<API.models.CampaignExecution> {
    return instance
        .request({
            url: urls.getExecutionByName(executionName),
            method: "GET",
        })
        .then((response) => response.data);
}
