import * as routeHelpers from "../../lib/routeHelpers";
import Drawer from "../Drawer";
import handshakeIcon from "../../images/handshake.svg";
import OutlineButton from "../OutlineButton";
import React, {useContext} from "react";
import styles from "./ManagerTraining.module.css";
import TextButton from "../TextButton";
import {CashierModeContext} from "../CashierModeContext";
import {useNavigate} from "react-router-dom";
import {TillAssignmentContext} from "../TillAssignmentContext";

const strings = {
    prompt: "Turn on Training Mode?",
    instructions:
        "You are activating ‘Training Mode’. While activated, employees will have full access to the ordering platform but no orders will be sent to the kitchen. Activity will not be recorded to a report and no payments will be taken.",
    no: "No, Thanks",
    start: "Start Training",
};

export default function ManagerTraining() {
    const {toggleIsTraining} = useContext(CashierModeContext);
    const {setTrainingModeAssignedTill} = useContext(TillAssignmentContext);
    const navigate = useNavigate();

    function onClose() {
        navigate(routeHelpers.managerMenu());
    }

    function startTraining() {
        toggleIsTraining(true);
        setTrainingModeAssignedTill(-1);
        navigate(routeHelpers.deviceSetup());
    }

    return (
        <Drawer onClose={onClose} isSmall={true}>
            <div className={styles.zeroContainer}>
                <div className={styles.promptContainer}>
                    <img className={styles.icon} src={handshakeIcon} alt="" />
                    <div className={styles.prompt}>{strings.prompt}</div>
                    <div className={styles.instructions}>
                        {strings.instructions}
                    </div>
                </div>
                <div className={styles.buttonsContainer}>
                    <OutlineButton
                        className={styles.actionButton}
                        mode="blue"
                        onClick={startTraining}>
                        <div className={styles.buttonLabel}>
                            {strings.start}
                        </div>
                    </OutlineButton>
                    <TextButton
                        className={styles.buttonLabel}
                        label={strings.no}
                        onClick={onClose}
                    />
                </div>
            </div>
        </Drawer>
    );
}
