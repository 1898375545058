import * as marketing from "../lib/api/marketing";
import logger from "../lib/logger";
import React, {
    useState,
    createContext,
    useEffect,
    useRef,
    useCallback,
} from "react";
import usePrevious from "../lib/usePrevious";
import {API} from "habit-core";
import {useAppSelector} from "../lib/hooks";

const EXECUTION_NAME = "Customer Display";
const LOAD_EXECUTIONS_INTERVAL_MS = 1800000; // 30 min in MS
const IMAGE_DURATION_MS = 20000; // 20 seconds

type CustomerDisplayMediaContextType = {
    activeElement: API.models.CampaignExecutionElement | undefined;
};

export const CustomerDisplayMediaContext =
    createContext<CustomerDisplayMediaContextType>({
        activeElement: undefined,
    });

export default function CustomerDisplayMediaProvider(props: {
    children: React.ReactNode;
}) {
    const [customerDisplayMedia, setCustomerDisplayMedia] = useState<{
        activeElementIndex: number | undefined;
        executionData: API.models.CampaignExecution | undefined;
    }>({
        activeElementIndex: undefined,
        executionData: undefined,
    });
    const currentExecutionDataNumElementsRef = useRef(0);
    const newExecutionDataRef = useRef<
        API.models.CampaignExecution | undefined
    >(undefined);
    const delayElementsUpdateRef = useRef(false);
    const switchElementTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const isCurrentOrderEmpty = useAppSelector(
        (state) =>
            !state.currentOrder.itemCustomizationIds.length &&
            !state.currentOrder.comboCustomizationIds.length &&
            !state.pos.currentOrder.giftCards.addFunds.length &&
            !state.pos.currentOrder.giftCards.purchase.length,
    );
    const prevIsCurrentOrderEmpty = usePrevious(isCurrentOrderEmpty);

    useEffect(() => {
        const loadExecutionData = () => {
            marketing
                .getExecutionData(EXECUTION_NAME)
                .then((executionData) => {
                    if (currentExecutionDataNumElementsRef.current > 0) {
                        delayElementsUpdateRef.current = true;
                        newExecutionDataRef.current = executionData;
                    } else {
                        currentExecutionDataNumElementsRef.current =
                            executionData.elements.length;
                        setCustomerDisplayMedia({
                            executionData: executionData,
                            activeElementIndex:
                                executionData?.elements?.length > 0
                                    ? 0
                                    : undefined,
                        });
                    }
                })
                .catch((err) => {
                    logger.error(err);
                });
        };

        loadExecutionData();
        const loadExecutionInterval = setInterval(
            loadExecutionData,
            LOAD_EXECUTIONS_INTERVAL_MS,
        );
        return () => {
            clearInterval(loadExecutionInterval);
        };
    }, []);

    const switchElement = useCallback(() => {
        if (delayElementsUpdateRef.current) {
            delayElementsUpdateRef.current = false;
            const newExecutionNumElements =
                newExecutionDataRef.current?.elements?.length ?? 0;
            currentExecutionDataNumElementsRef.current =
                newExecutionNumElements;

            setCustomerDisplayMedia({
                executionData: newExecutionDataRef.current,
                activeElementIndex: newExecutionNumElements > 0 ? 0 : undefined,
            });

            return;
        }

        setCustomerDisplayMedia((prevCustomerDisplayMedia) => {
            const numElements =
                prevCustomerDisplayMedia.executionData?.elements?.length ?? 0;
            if (prevCustomerDisplayMedia.activeElementIndex === undefined) {
                return {
                    ...prevCustomerDisplayMedia,
                    activeElementIndex: numElements > 0 ? 0 : undefined,
                };
            }

            let nextActiveElementIndex =
                prevCustomerDisplayMedia.activeElementIndex + 1;
            if (nextActiveElementIndex >= numElements) {
                nextActiveElementIndex = 0;
            }

            return {
                ...prevCustomerDisplayMedia,
                activeElementIndex: nextActiveElementIndex,
            };
        });
    }, []);

    const activeElement =
        customerDisplayMedia.activeElementIndex !== undefined
            ? customerDisplayMedia.executionData?.elements?.[
                  customerDisplayMedia.activeElementIndex
              ]
            : undefined;

    useEffect(() => {
        if (activeElement === undefined) {
            return;
        }

        if (activeElement.type === "image") {
            switchElementTimeoutRef.current = setTimeout(
                switchElement,
                IMAGE_DURATION_MS,
            );
        } else {
            switchElementTimeoutRef.current = setTimeout(
                switchElement,
                activeElement.data.durationMs,
            );
        }
    }, [activeElement]);

    useEffect(() => {
        if (!isCurrentOrderEmpty && prevIsCurrentOrderEmpty) {
            if (switchElementTimeoutRef.current !== undefined) {
                clearTimeout(switchElementTimeoutRef.current);
            }
        } else if (isCurrentOrderEmpty && !prevIsCurrentOrderEmpty) {
            switchElement();
        }
    }, [isCurrentOrderEmpty, prevIsCurrentOrderEmpty, switchElement]);

    useEffect(() => {
        return () => {
            if (switchElementTimeoutRef.current !== undefined) {
                clearTimeout(switchElementTimeoutRef.current);
            }
        };
    }, []);

    return (
        <CustomerDisplayMediaContext.Provider
            value={{
                activeElement: activeElement,
            }}>
            {props.children}
        </CustomerDisplayMediaContext.Provider>
    );
}
