import cn from "classnames";
import minusIcon from "../../images/minus.svg";
import minusGreyIcon from "../../images/minus-grey.svg";
import plusIcon from "../../images/plus.svg";
import plusGreyIcon from "../../images/plus-grey.svg";
import React from "react";
import splitIcon from "../../images/split.svg";
import splitIconGrey from "../../images/split-grey.svg";
import styles from "./CheckoutProductBase.module.css";
import trashIcon from "../../images/trash.svg";
import {Link} from "react-router-dom";

const strings = {
    edit: "Edit",
};

type Props = {
    className?: string;
    editable: "editable" | "removable" | "uneditable";
    name: string;
    quantity: number;
    formattedPrice: string;
    onSplit?: () => void;
    onChangeQuantity?: (quantity: number) => void;
    canIncreaseQuantity?: boolean;
    canDecreaseQuantity?: boolean;
    editTo?: string;
    onRemove: () => void;
    content?: React.ReactNode;
    leftText?: string;
};

export default function CheckoutProductBase(props: Props) {
    function renderLeft() {
        const splitDisabled = !props.onSplit || props.quantity < 2;

        return (
            <div className={styles.left}>
                <div className={styles.upperLeft}>
                    <div className={styles.quantityButtonsContainer}>
                        <button
                            className={cn(
                                styles.quantityButton,
                                styles.increase,
                            )}
                            disabled={!props.canIncreaseQuantity}
                            onClick={() =>
                                props.onChangeQuantity
                                    ? props.onChangeQuantity(props.quantity + 1)
                                    : null
                            }>
                            <img
                                src={
                                    props.canIncreaseQuantity
                                        ? plusIcon
                                        : plusGreyIcon
                                }
                                alt=""
                            />
                        </button>
                        <button
                            className={styles.quantityButton}
                            disabled={!props.canDecreaseQuantity}
                            onClick={() =>
                                props.onChangeQuantity
                                    ? props.onChangeQuantity(props.quantity - 1)
                                    : null
                            }>
                            <img
                                className={styles.minusIcon}
                                src={
                                    props.canDecreaseQuantity
                                        ? minusIcon
                                        : minusGreyIcon
                                }
                                alt=""
                            />
                        </button>
                    </div>
                    <div className={styles.quantity}>{props.quantity}</div>
                </div>
                {props.leftText ? (
                    <div className={styles.leftText}>{props.leftText}</div>
                ) : null}
                <div className={styles.lowerLeft}>
                    <button
                        className={cn(styles.lowerLeftButton, styles.remove)}
                        onClick={props.onRemove}>
                        <img src={trashIcon} alt="" />
                    </button>
                    <button
                        className={cn(styles.lowerLeftButton, styles.split)}
                        disabled={splitDisabled}
                        onClick={() =>
                            props.onSplit ? props.onSplit() : null
                        }>
                        <img
                            src={splitDisabled ? splitIconGrey : splitIcon}
                            alt=""
                        />
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className={cn(styles.container, props.className)}>
            {props.editable === "editable" ? renderLeft() : null}

            <div className={styles.right}>
                <div className={styles.nameOuterContainer}>
                    <div className={styles.nameInnerContainer}>
                        <div className={styles.name}>
                            {props.editable !== "editable"
                                ? `${props.quantity} `
                                : null}
                            {props.name}
                        </div>
                        {props.editTo && props.editable === "editable" ? (
                            <Link className={styles.edit} to={props.editTo}>
                                {strings.edit}
                            </Link>
                        ) : null}
                    </div>
                    <div className={styles.price}>
                        {props.formattedPrice}
                        {props.editable === "removable" ? (
                            <button
                                className={cn(styles.lowerLeftButton)}
                                onClick={props.onRemove}>
                                <img src={trashIcon} alt="" />
                            </button>
                        ) : null}
                    </div>
                </div>
                {props.content ?? null}
            </div>
        </div>
    );
}
